exports.components = {
  "component---src-pages-1-secure-index-jsx": () => import("./../../../src/pages/1secure/index.jsx" /* webpackChunkName: "component---src-pages-1-secure-index-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-ajuda-index-jsx": () => import("./../../../src/pages/ajuda/index.jsx" /* webpackChunkName: "component---src-pages-ajuda-index-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-change-tracker-index-jsx": () => import("./../../../src/pages/change-tracker/index.jsx" /* webpackChunkName: "component---src-pages-change-tracker-index-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-default-index-jsx": () => import("./../../../src/pages/default/index.jsx" /* webpackChunkName: "component---src-pages-default-index-jsx" */),
  "component---src-pages-endpoint-protector-index-jsx": () => import("./../../../src/pages/endpoint-protector/index.jsx" /* webpackChunkName: "component---src-pages-endpoint-protector-index-jsx" */),
  "component---src-pages-enterprise-auditor-index-jsx": () => import("./../../../src/pages/enterprise-auditor/index.jsx" /* webpackChunkName: "component---src-pages-enterprise-auditor-index-jsx" */),
  "component---src-pages-group-id-index-jsx": () => import("./../../../src/pages/group-id/index.jsx" /* webpackChunkName: "component---src-pages-group-id-index-jsx" */),
  "component---src-pages-group-id-password-index-jsx": () => import("./../../../src/pages/group-id-password/index.jsx" /* webpackChunkName: "component---src-pages-group-id-password-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-ndc-index-jsx": () => import("./../../../src/pages/ndc/index.jsx" /* webpackChunkName: "component---src-pages-ndc-index-jsx" */),
  "component---src-pages-netwrix-index-jsx": () => import("./../../../src/pages/netwrix/index.jsx" /* webpackChunkName: "component---src-pages-netwrix-index-jsx" */),
  "component---src-pages-netwrix-recovery-index-jsx": () => import("./../../../src/pages/netwrix-recovery/index.jsx" /* webpackChunkName: "component---src-pages-netwrix-recovery-index-jsx" */),
  "component---src-pages-parcerias-index-jsx": () => import("./../../../src/pages/parcerias/index.jsx" /* webpackChunkName: "component---src-pages-parcerias-index-jsx" */),
  "component---src-pages-password-policy-enforcer-index-jsx": () => import("./../../../src/pages/password-policy-enforcer/index.jsx" /* webpackChunkName: "component---src-pages-password-policy-enforcer-index-jsx" */),
  "component---src-pages-password-secure-index-jsx": () => import("./../../../src/pages/password-secure/index.jsx" /* webpackChunkName: "component---src-pages-password-secure-index-jsx" */),
  "component---src-pages-pingcastle-index-jsx": () => import("./../../../src/pages/pingcastle/index.jsx" /* webpackChunkName: "component---src-pages-pingcastle-index-jsx" */),
  "component---src-pages-policypak-index-jsx": () => import("./../../../src/pages/policypak/index.jsx" /* webpackChunkName: "component---src-pages-policypak-index-jsx" */),
  "component---src-pages-privilege-secure-index-jsx": () => import("./../../../src/pages/privilege-secure/index.jsx" /* webpackChunkName: "component---src-pages-privilege-secure-index-jsx" */),
  "component---src-pages-strongpoint-index-jsx": () => import("./../../../src/pages/strongpoint/index.jsx" /* webpackChunkName: "component---src-pages-strongpoint-index-jsx" */),
  "component---src-pages-syxsense-index-jsx": () => import("./../../../src/pages/syxsense/index.jsx" /* webpackChunkName: "component---src-pages-syxsense-index-jsx" */),
  "component---src-pages-threat-manager-index-jsx": () => import("./../../../src/pages/threat-manager/index.jsx" /* webpackChunkName: "component---src-pages-threat-manager-index-jsx" */),
  "component---src-pages-threat-prevention-index-jsx": () => import("./../../../src/pages/threat-prevention/index.jsx" /* webpackChunkName: "component---src-pages-threat-prevention-index-jsx" */),
  "component---src-pages-usercube-index-jsx": () => import("./../../../src/pages/usercube/index.jsx" /* webpackChunkName: "component---src-pages-usercube-index-jsx" */)
}

